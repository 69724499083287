import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BikeService from "../../Services/bikeService";

const BikeDashboardMain = () => {
    const [bikes, setBikes] = useState([]);
    const bikeService = new BikeService();

    const navigate = useNavigate();

    useEffect(() => {
        bikeService.getBikes().then(fetchedBikes => {
            setBikes(fetchedBikes);
        }).catch(error => {
            console.error('Error fetching bikes:', error);
        });
    }, []); 

    return (
        <div className="bikes-container">
            <div className="hstackMimic" style={{display:"flex", justifyContent:'space-between', alignItems:"center", alignContent:"center"}}>
            <h1 style={{margin: "40px 0px 0px 20px"}}>Bikes</h1>
            <span onClick={()=>{navigate("/addbike")}} className="material-symbols-rounded" style={{display: "block", textAlign: "center", margin: "0px 20px 0px 0px", padding: "0", fontSize: "30px"}}>
add
</span>
            </div>
            {bikes.map((bike, index) => (
                <div key={index} className="bike-card">
                <h3>{bike.brand} {bike.model} ({bike.year})</h3>
                <p>Status: {bike.status}</p>
                <p>Registration: {bike.registration}</p>
            </div>))}
        </div>
    );
}

export default BikeDashboardMain;

