import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../Images/ShapShapLogo.png';
import Popup from '../Helpers/Popup';
import AuthService from '../../Services/authService';
import { useDispatch, useSelector } from 'react-redux';

const Sidebar = ({ isSidebarVisible, toggleSidebar }) => {
const [signOutPopup, setSignOutPopup] = useState(false);
const state = useSelector((state) => state.auth);
const dispatch = useDispatch();

const authService = new AuthService();

const togglePopup = () => {
    setSignOutPopup(!signOutPopup);
  };

const handleSignOut = () => {
    authService.logout(dispatch);
}

  return (
    <>
    {!isSidebarVisible && 
      <button className="toggle-button" style={{color:"#000"}} onClick={toggleSidebar}>
        {'☰'}
      </button> }
      <div className={isSidebarVisible ? "sidebar-overlay" : ""} onClick={toggleSidebar}></div>
      <div className={`sidebar ${isSidebarVisible ? 'show' : 'hidden'}`}>
        <div className='hstackMimic' style={{marginBottom:"20px"}}>
        <img src={logo} alt="ShapShap Logo" style={{width:"36px"}} />
        <h2 style={{fontWeight: "600", color: "#fff", paddingLeft: "10px"}}>ShapShap</h2>
        </div>
        <p className='sidebar-category'>Live</p>
        <NavLink to="/bikes" activeClassName="active">Bikes</NavLink>
        <p className='sidebar-category'>In Progress</p>
        <NavLink to="/" activeClassName="active">Overview</NavLink>
        <NavLink to="/drivers" activeClassName="active">Drivers</NavLink>
        <p className='sidebar-category'>Future Plans</p>
        <NavLink to="/deliveries" activeClassName="active">Deliveries</NavLink>
        <NavLink to="/map" activeClassName="active">Map</NavLink>
        <NavLink to="/staff" activeClassName="active">Staff</NavLink>
        <NavLink to="/payments" activeClassName="active">Payments</NavLink>
        <NavLink to="/organziation" activeClassName="active">Organziation</NavLink>
        <NavLink to="/contracts" activeClassName="active">Contracts</NavLink>
        <NavLink to="/maintenance" activeClassName="active">Maintenance</NavLink>
        <div className='sidebar-footer'>
                <p style={{color:"white", margin: "4px 10px", textAlign:"left", padding:"4px 10px 10px 10px", fontWeight:"500"}}>{}</p>
                <button className='sidebar-sign-out' onClick={togglePopup}>Sign Out</button>
                </div>
      </div>
      <Popup show={signOutPopup} onClose={togglePopup} action={handleSignOut} actionText={"Sign Out"}>
        <h4>Are you sure you would like to sign out?</h4>
      </Popup>
    </>
  );
};

export default Sidebar;
