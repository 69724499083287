import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BikeService from "../../Services/bikeService";

const AddBikeMain = () => {
    const [brand, setBrand] = useState('Honda');
    const [model, setModel] = useState('ACE 125');
    const [registration, setRegistration] = useState('');
    const [status, setStatus] = useState('Rented');
    const [year, setYear] = useState('2022');
    const brands = ["Honda", "Hero", "Big Boy"]
    const models = ["ACE 125", "Dash 125 Fi", "D-Lite 125", "Eco Delux"]
    const years = ["2022", "2023", "2024"]
    const statuses = ["Rented", "Maintenance", "Vacant", "Driver Owned"]
    const bikeService = new BikeService();

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        bikeService.createBike(brand, model, year, status, registration)
        .then((result) => {
            console.log("result",result);
            navigate("/bikes")
        }).catch((error) => {
            console.log("signwithcode",error);
        }).finally(() => {
            console.log("finally");
        })
    };

    return (
        <div className="add-bike-form">
            <span onClick={() => {navigate(-1)}} className="material-symbols-rounded" style={{display: "block", textAlign: "left", margin: "0", padding: "0", fontSize: "30px"}}>
chevron_left
</span>
            <h1>Add Bike</h1>
            <form onSubmit={handleSubmit}>
                <label>BRAND</label>
            <select className="styledPicker" value={brand} onChange={(e) => setBrand(e.target.value)}>
  {brands.map((brand, index) => (
    <option key={index} value={brand}>{brand}</option>
  ))}
</select>
<label>MODEL</label>
            <select className="styledPicker" value={model} onChange={(e) => setModel(e.target.value)}>
  {models.map((model, index) => (
    <option key={index} value={model}>{model}</option>
  ))}
</select>
<label>YEAR</label>
            <select className="styledPicker" value={year} onChange={(e) => setYear(e.target.value)}>
  {years.map((year, index) => (
    <option key={index} value={year}>{year}</option>
  ))}
</select>
<label>STATUS</label>
            <select className="styledPicker" value={status} onChange={(e) => setStatus(e.target.value)}>
  {statuses.map((status, index) => (
    <option key={index} value={status}>{status}</option>
  ))}
</select>
                <label>
                    REGISTRATION NO
                    <input type="text" value={registration} onChange={(event) => setRegistration(event.target.value)} />
                </label>
                <input style={{
                    width: '100%',
                    padding: '15px',
                    fontSize: '16px',
                    fontWeight: '550',
                    backgroundColor: 'var(--accent-color)',
                    color: '#fff',
                    border: 'none',
                    margin: "60px 0px 0px 0px",
                    borderRadius: '8px',
                    cursor: 'pointer',
                }} type="submit" value="Add Bike" />
            </form>
        </div>
    );
}

export default AddBikeMain;

