import { firestoreInstance } from "../index";
import { addDoc, collection, getDocs } from "firebase/firestore";

class BikeService {

    async createBike(brand, model, year, status, registration ) {
        try {
            const data = {
                type: "bike",
                brand: brand,
                model: model,
                year: year,
                status: status,
                registration: registration,
            }
            const colRef = collection(firestoreInstance, "Bikes")
            const docRef = await addDoc(colRef, data);
            return docRef.id;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getBikes() {
        try {
            const colRef = collection(firestoreInstance, "Bikes")
            const bikesSnapshot = getDocs(colRef)
            const bikeData = (await bikesSnapshot).docs.map(doc => doc.data());
            console.log(bikeData);
            return bikeData;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

}
    
    export default BikeService;