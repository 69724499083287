import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Services/authService";
import { AuthContext } from "../../Helpers/Contexts/AuthProvider";
import { RecaptchaVerifier } from "firebase/auth";
import { authInstance } from "../..";
import logo from "../../Images/ShapShapLogo.png"

const LoginMain = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const { setConfirmationResult } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const authService = new AuthService();
    
    useEffect(() => {
        if (!window.recaptchaVerifier) {
            console.log("no recaptcha");
        window.recaptchaVerifier = new RecaptchaVerifier(authInstance, 'recaptchaContainer', {
            'size': 'invisible',
            'callback': async (response) => {
                console.log("callback Success");
            },
            'expired-callback': () => {
                console.log("expired");
            },
            'error-callback': (error) => {
                console.log("error");
                console.log(error);
            }
        });
    }

    return () => {
        if (window.recaptchaVerifier) {
            window.recaptchaVerifier.clear();
            window.recaptchaVerifier = null;
            if (window.grecaptcha && window.recaptchaWidgetId !== undefined) {
                window.grecaptcha.reset(window.recaptchaWidgetId);
            }
        }
    };
}, []); 

    const sendCode = async () => {
        setLoading(true);
        console.log("Send Code", window.recaptchaVerifier)
    window.recaptchaVerifier.verify()
        try {
            const result = await authService.sendVerificationCode("+27" + phoneNumber, window.recaptchaVerifier);
            setConfirmationResult(result);
            setLoading(false);
            navigate("/verify");
            if (window.recaptchaVerifier) {
                window.recaptchaVerifier.clear();
                window.recaptchaVerifier = null;
                if (window.grecaptcha && window.recaptchaWidgetId !== undefined) {
                    window.grecaptcha.reset(window.recaptchaWidgetId);
                }
            }
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };
    //TODO: add handler for error and loading of sending code 
  //  {loading && <p>Loading...</p>}

    const handleInputChange = (event) => setPhoneNumber(event.target.value);

    return (
        <div className='recaptchaParent' style={styles.container}>
            <img src={logo} alt="text" style={styles.image}/>
            <h2>Shap Shap Admin</h2>
            <div style={styles.inputGroup}>
                <span style={styles.countryCode}>+27</span>
                <input 
                    type="tel" 
                    value={phoneNumber}
                    onChange={handleInputChange}
                    placeholder="Mobile Number"
                    style={styles.input}
                />
            </div>
            {error && <p className='errorText'>{error}</p>}
            <div></div>
            <button  id="recaptchaContainer" onClick={sendCode} style={styles.continueButton}>
      {loading ? <div className="loading-indicator"></div> : "Continue"}
    </button>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center', // Full height of the viewport
        width: '90%',
        maxWidth: '360px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#fff',
    },
    disclaimer: {
        fontSize: '12px',
        fontWeight: 'normal',
        textAlign: 'left',
        color: 'rgb(161, 161, 161)'
    },
    label: {
        display: 'block',
        marginBottom: '10px',
        fontSize: '16px',
    },
    inputGroup: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginBottom: '20px',
    },
    countryCode: {
        marginRight: '10px',
        backgroundColor: '#f0f0f0',
        padding: '12px 10px',
        borderRadius: '8px',
        border: '0px solid #ccc',
    },
    input: {
        width: '100%',
        flex: 1,
        padding: '12px 10px',
        fontSize: '16px',
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        border: '0px solid #ccc',
        outline: 'none',
    },
    continueButton: {
        width: '100%',
        padding: '15px',
        fontSize: '16px',
        fontWeight: '550',
        backgroundColor: 'gold',
        color: '#000',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
    },
    image: {
        width: '200px',
    }
}

export default LoginMain;
