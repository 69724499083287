import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './Helpers/Contexts/AuthProvider';
import { ProtectedRoute } from './Helpers/Objects/ProtectedRoute';
import LoginMain from './Views/Auth/LoginMain';
import CodeVerificationScreen from './Views/Auth/CodeVerificationScreen';
import Sidebar from './Views/Dashboard/Sidebar';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import DashboardOverview from './Views/Dashboard/DashboardOverview';
import AddBikeMain from './Views/Bikes/AddBikeMain';
import BikeDashboardMain from './Views/Bikes/BikesDashboardMain';

const MainContent = ({ children, isSidebarVisible }) => {
  return <div className={`main-content ${isSidebarVisible ? 'sidebar-visible' : ''}`}>{children}</div>;
};

function App() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const state = useSelector((state) => state.auth)

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  return (
    <div>
      <BrowserRouter>
      <AuthProvider>
        {true && <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} /> }
        <MainContent isSidebarVisible={isSidebarVisible}>
      <Routes>
        <Route element={<ProtectedRoute isAllowed={true} />}>
          <Route path='/' element={<DashboardOverview />} />
          <Route path='/bikes' element={<BikeDashboardMain />} />
          <Route path='/addbike' element={<AddBikeMain />} />
        <Route path="*" element={<p style={{textAlign: 'center', width:"100%"}}>
          This page does not exist.
        </p>} />
        </Route>
        <Route element={<ProtectedRoute isAllowed={!state.isSignedIn} redirectPath='/' />}>
        <Route path="/login" element={<LoginMain />} />
        <Route path="/verify" element={<CodeVerificationScreen />} />
        </Route>
      </Routes>
      </MainContent>
      </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
