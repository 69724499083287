import { SET_USER } from "../Actions/authActions";

const initialState = {
    isSignedIn: false,
    user: {fullName: "Guest"},
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload.user,
                isSignedIn: action.payload.isSignedIn,
            };
        default:
             return state
    }
};

export default authReducer