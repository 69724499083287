import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const DashboardOverview = () => {
    const state = useSelector((state) => state.auth);
  return (
    <div className="dashboard-overview">
        <h1>Welcome back, {state.user.fullName}!</h1>
        <div className="dashboard-section" id="drivers">
    <h2>Drivers</h2>
    <div className="dashboard-statistics">
        <div className="statistic-item">
            <span className="statistic-title">New Drivers This Week</span>
            <span className="statistic-value">5</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Average Rating</span>
            <span className="statistic-value">4.3 / 5</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Completed Rides</span>
            <span className="statistic-value">120 Rides</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Issues Reported</span>
            <span className="statistic-value">3 Issues</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Top Performer</span>
            <span className="statistic-value">John Doe</span>
        </div>
    </div>
</div>

<div className="dashboard-section" id="payments">
    <h2>Payments</h2>
    <div className="dashboard-statistics">
        <div className="statistic-item">
            <span className="statistic-title">Total Revenue</span>
            <span className="statistic-value">$5,000</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Transactions This Week</span>
            <span className="statistic-value">200</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Outstanding Payments</span>
            <span className="statistic-value">$1,200</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Average Transaction Value</span>
            <span className="statistic-value">$25</span>
        </div>
    </div>
</div>


      <div className="dashboard-section" id="bikes">
    <h2>Bikes</h2>
    <div className="dashboard-statistics">
        <div className="statistic-item">
            <span className="statistic-title">Total Bikes</span>
            <span className="statistic-value">150</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Bikes in Use</span>
            <span className="statistic-value">120</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Bikes in Maintenance</span>
            <span className="statistic-value">10</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">New Bikes This Month</span>
            <span className="statistic-value">5</span>
        </div>
    </div>
</div>


<div className="dashboard-section" id="maintenance">
    <h2>Maintenance</h2>
    <div className="dashboard-statistics">
        <div className="statistic-item">
            <span className="statistic-title">Scheduled Maintenances</span>
            <span className="statistic-value">15</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Maintenance Costs This Month</span>
            <span className="statistic-value">$800</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Urgent Repairs</span>
            <span className="statistic-value">3</span>
        </div>
        <div className="statistic-item">
            <span className="statistic-title">Maintenance Duration</span>
            <span className="statistic-value">2 Days (Avg)</span>
        </div>
    </div>
</div>

    </div>
  );
};

export default DashboardOverview;
